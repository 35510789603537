.course-tile{
  position: relative;
  float: left;
  margin: 0 30px 30px 0;
  padding: 158px 0 0 18px;
  width: 310px;
  height: 200px;
  font-size: 21px;
  font-weight: bold;
  overflow: hidden;
  background-color: #000;
  color: $white !important;
  text-decoration: none !important;

  &:hover, &:focus{
    color: $white;
  }

  &:nth-of-type(3n){
    margin-right: 0;
  }

  &__bg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 600ms ease;
    background-size: cover;
    background-color: #000;

    &:hover{
      transform: scale(1.04);
      opacity: 0.7;
    }
  }

  &__darken{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 53px;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  }

  &__text{
    position: absolute;
    bottom: 11px;
    z-index: 1;
  }
}
