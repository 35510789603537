.social-menu{
  margin: 0;
  padding: 0;
  list-style: none;
  // @extend %clearfix;

  a{
    display: block;
    float: right;
    margin-left: $space-m;
    color: rgba(0,0,0,0.25);
    font-size: 25px;
    text-decoration: none;
    transition: color 300ms;

    &:hover{
      transition: color 200ms;
      color: rgba(0,0,0,0.37);
    }
  }

  &--dark{
    a{
      color: rgba(255,255,255,0.25);

      &:hover{
        color: rgba(255,255,255,0.4);
      }
    }
  }
}
