/*!
Theme Name: Tanzschule Schwarzenhölzer by Leo Weigand
Author: Leonard Weigand
Author URI: https://leoweigand.de/
Version: 0.1.0
Text Domain: lw_schwarzenhoelzer
*/


// Imports
@import 'custom-reset';
@import 'variables';

// typography
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
article.post{
  @import 'typography';
}

@import 'course-signup';
@import 'social-menu';
@import 'cta-button'; // replace this
@import 'buttons';
@import 'course-tile';
@import 'events';

html,body{
  min-height: 100%;
}

body{
  -webkit-font-smoothing: antialiased; // leave enabled on normal dpi displays?
  font-family: $font;
}

#header{
  position: relative;
  height: 400px;
  background-image: url('assets/header-bg.png');
  opacity: 0.8;
  color: $white;
}

.header-gradient{
  position: absolute;
  width: 100%;
  height: 100%;
  background: $brand-gradient;
  opacity: 0.85;
}

.nav-main{
  position: relative;
  float: right;
  margin-top: 40px;

  ul{
    list-style: none;
    padding-left: 0;
  }

  li{
    float: left;
    margin-left: $space-m;
    @extend .text-bold-light;
  }

  a{
    color: inherit;
    text-decoration: none;
  }

  .magic-line{
    position: absolute;
    top: 40px;
    height: 2px;
    background: $white;
    transition: transform 250ms ease;

    &.scaled{
      transform: scaleX(0);
    }
  }
}

.location-selection {
  position: relative;
  height: 45px;

  > ul{
    display: none;
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    padding: 8px 0;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);

    &::before{
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 13px);
      width: 39px;
      height: 24px;
      background: url("assets/nubsi.svg") no-repeat top left/cover;
    }

    li{
      position: relative;
      margin: 0;
      width: 100%;
      z-index: 10;
    }

    a {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 24px;
      font: bold 16px/40px $font;
      letter-spacing: 0.75px;
      white-space: nowrap;
      color: $copy-color;
      &:hover{
        background: #eee;
      }
    }
  }
  &:hover > ul{
    display: block;
  }
}
.logolink{
  display: block;
  margin-top: 24px;
  padding-left: 63px;
  padding-top: 3px;
  height: 72px;
  background: url('assets/logo.png') top left/50px 72px no-repeat;
  font-size: 22px;
  font: 22px/25px $font;
  letter-spacing: 0.75px;
  color: $white;

  &:hover{
    text-decoration: none;
    color: $white;
  }
}

.contentheader{
  position: relative;
  text-shadow: 0 2px 3px rgba(0,0,0,0.12);

  &__offsettop{
    position: absolute;
    bottom: 100%;
    width: 100%;
  }

  &__h1, &__currentschool{
    margin: 0;
  }
  &__h1{
    @extend .article-h1;
    margin-bottom: 3px;
  }
  &__currentschool{
    @extend .currentschool;
    margin-bottom: $space-s;
  }
}

.article-content{
  padding: $space-m 0;
}

.dark-col{
  background: $dark-bg-gradient;
  article.post p{
    color: $white;
  }
}


#footer{
  padding: $space-l 0;
  background: $dark-bg-gradient;
  color: #fff;

  h2{
    margin: 0 0 $space-m;
    font: bold 22px $font;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }

  div > a{
    display: block;
    margin-bottom: $space-s;
    font: 17px $font;
    color: rgba(255,255,255,0.8);
    // transition: color 200ms;

    &:hover{
      color: $white;
      text-decoration: none;
    }
  }
}
