// clearfix
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Spacings
$space-s: 17px;
$space-m: 30px;
$space-l: 60px;
.spacer-s{
  height: $space-s;
}
.spacer-m{
  height: $space-m;
}
.spacer-l{
  height: $space-l;
}


// Colors
$brand-color: #F7B152;
$brand-highlight: #ffc05c;
$brand-secondary: #D13C53;
$white: #fff;
$copy-color: #191919;
$error-color: #F75252;

// Gradients
$brand-gradient: linear-gradient(-4deg, rgba(209,61,83,0.83) 0%, #F7C652 100%);
$dark-bg-gradient: linear-gradient(-180deg, #4A5059 0%, #29313E 100%);


// Typography
$font: 'PT Sans', Helvetica, Arial, sans-serif;

.article-h1{
  font: bold 40px $font;
  color: $white;
  letter-spacing: 0.79px;
}

.currentschool{
  font: 18px $font;
  color: $white;
}

.text-bold-light{
  font: bold 20px $font;
  color: $white;
  letter-spacing: 0.22px;
}
