.button{
  display: inline-block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #eee;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  border-radius: 2px;
  font-weight: bold;
  font-size: 21px;

  &--primary{
    background: $brand-color;
    color: $white;

    &:hover, &:focus{
      color: $white;
    }

    &:hover{
      background: $brand-highlight;
    }
  }

  &--stretch{
    width: 100%;
  }
}
