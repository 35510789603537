.cta-button{
  display: inline-block;
  padding: 0 $space-l;
  height: 50px;
  text-align: center;
  color: $white !important;
  text-decoration: none !important;
  font: bold 17px/50px $font;
  letter-spacing: 0.2px;
  background-color: $brand-color;
  transition: background-color 200ms;

  &:hover{
    background-color: $brand-highlight;
    color: $white;
    text-decoration: none;
  }

  &::before{
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(assets/right-arrow.svg) no-repeat;
    background-size: contain;
    margin-right: 7px;
    position: relative;
    top: 3px;
  }
}
