.course-signup-panel{
  display: flex;
  margin-bottom: $space-s;
  background: #FAFAFA;
  transition: box-shadow 250ms ease;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04);

  &__fields{
    padding: 15px $space-m;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    border: 1px solid #E2E2E2;
    border-right-width: 0;

    // needed in order for tables to look the same
    // replace with javascript version if gets too complicated
      // it would remove fixed widths and then just set the
      // max-width neccessary by iterating over all tables
    > div{
      &:nth-child(1){
        width: 180px;
      }
      &:nth-child(2){
        width: 100px;
      }
      &:nth-child(3){
        width: 125px;
      }
      &:nth-child(4){
        width: 105px;
      }
      &:nth-child(5){
        width: 90px;
      }
    }
  }

  &--colheaders{
    background: none;
    box-shadow: none;
    margin: $space-m 0 12px;

    > div{
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }

    > div:last-child{
      visibility: hidden;
      height: 0;
    }
  }

  &__button{
    padding: 2px 0 0;
    width: 160px;
    background-color: $brand-color;
    border: none;
    font: bold 17px $font;
    text-align: center;
    letter-spacing: 0.2px;
    color: $white;
    transition: background-color 250ms ease;

    &::before{
      content: '';
      display: inline-block;
      width: 17px;
      height: 17px;
      background: url(assets/right-arrow.svg) no-repeat;
      background-size: contain;
      margin-right: 7px;
      position: relative;
      top: 3px;
    }

    &:hover{
      background-color: $brand-highlight;
    }

    &--full{
      pointer-events: none;
      background: $brand-secondary;

      &::before{
        display: none;
      }
    }
  }
}