// deactivate boootstrap's responsive features
.container {
  width: 1020px !important;
}


// clear default focus styles
a{
  color: inherit;

  &:hover, &:focus{
    text-decoration: none;
    color: inherit;
  }
}
a, button, input, textarea{
  &:hover, &:focus{
    outline: none;
  }
}

button{
  border: none;
  background: none;
}
