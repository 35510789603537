// remove margin for first element
:first-child{
  margin-top: 0;
}

p, ul, ol{
  font: 16px $font;
  color: $copy-color;
}

p{
  width: 58.33%;
}

ul, ol{
  padding-left: 1em;
}

h2{
  margin-top: $space-m + 4px;
  font: bold 27px $font;
  letter-spacing: 0.5px;
  color: $copy-color;
}

h3{
  margin: 0 0 15px;
  font: bold 20px $font;
}

a {
  color: $brand-secondary;
  text-decoration: underline;
}