.events{
    .post{
        margin-top: 46px;
    }

    a{
        text-decoration: none !important;
    }

    h3 a{
        color: inherit;
    }
}

.more-events-wrapper{
    padding: $space-l 0;
    text-align: center;
}

.events-all{
    padding: $space-s 0 $space-l;
}



.events-caption{
    margin: $space-l 0 0;
    font: normal 30px $font;
}

.event-date{
    display: block;
    height: 135px;
    padding-top: 15px;
    border-radius: 6px;
    background: #D13D53;
    font: bold 19px Arial, sans-serif;
    text-align: center;
    color: $white !important;

    &:hover{
        color: $white;
    }

    &__month{
        text-transform: uppercase;
    }

    &__day{
        margin-top: 2px;
        font-size: 78px;
    }
}

.event-details{
    margin-top: $space-s;
  
    &__interactions a{
      padding: 2px 8px;
      border-radius: 3px;
      font-weight: bold;
      color: #C8C8C8 !important;
  
      &:hover{
        color: #A6A6A6;
        background: rgba(0,0,0,0.07);
      }
    }
  }