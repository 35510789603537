@charset "UTF-8";
/*!
Theme Name: Tanzschule Schwarzenhölzer by Leo Weigand
Author: Leonard Weigand
Author URI: https://leoweigand.de/
Version: 0.1.0
Text Domain: lw_schwarzenhoelzer
*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
.container {
  width: 1020px !important;
}

a {
  color: inherit;
}

a:hover, a:focus {
  text-decoration: none;
  color: inherit;
}

a:hover, a:focus, button:hover, button:focus, input:hover, input:focus, textarea:hover, textarea:focus {
  outline: none;
}

button {
  border: none;
  background: none;
}

.spacer-s {
  height: 17px;
}

.spacer-m {
  height: 30px;
}

.spacer-l {
  height: 60px;
}

.article-h1, .contentheader__h1 {
  font: bold 40px "PT Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  letter-spacing: 0.79px;
}

.currentschool, .contentheader__currentschool {
  font: 18px "PT Sans", Helvetica, Arial, sans-serif;
  color: #fff;
}

.text-bold-light, .nav-main li {
  font: bold 20px "PT Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  letter-spacing: 0.22px;
}

article.post :first-child {
  margin-top: 0;
}

article.post p, article.post ul, article.post ol {
  font: 16px "PT Sans", Helvetica, Arial, sans-serif;
  color: #191919;
}

article.post p {
  width: 58.33%;
}

article.post ul, article.post ol {
  padding-left: 1em;
}

article.post h2 {
  margin-top: 34px;
  font: bold 27px "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
  color: #191919;
}

article.post h3 {
  margin: 0 0 15px;
  font: bold 20px "PT Sans", Helvetica, Arial, sans-serif;
}

article.post a {
  color: #D13C53;
  text-decoration: underline;
}

.course-signup-panel {
  display: flex;
  margin-bottom: 17px;
  background: #FAFAFA;
  transition: box-shadow 250ms ease;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04);
}

.course-signup-panel__fields {
  padding: 15px 30px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border: 1px solid #E2E2E2;
  border-right-width: 0;
}

.course-signup-panel__fields > div:nth-child(1) {
  width: 180px;
}

.course-signup-panel__fields > div:nth-child(2) {
  width: 100px;
}

.course-signup-panel__fields > div:nth-child(3) {
  width: 125px;
}

.course-signup-panel__fields > div:nth-child(4) {
  width: 105px;
}

.course-signup-panel__fields > div:nth-child(5) {
  width: 90px;
}

.course-signup-panel--colheaders {
  background: none;
  box-shadow: none;
  margin: 30px 0 12px;
}

.course-signup-panel--colheaders > div {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.course-signup-panel--colheaders > div:last-child {
  visibility: hidden;
  height: 0;
}

.course-signup-panel__button {
  padding: 2px 0 0;
  width: 160px;
  background-color: #F7B152;
  border: none;
  font: bold 17px "PT Sans", Helvetica, Arial, sans-serif;
  text-align: center;
  letter-spacing: 0.2px;
  color: #fff;
  transition: background-color 250ms ease;
}

.course-signup-panel__button::before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(assets/right-arrow.svg) no-repeat;
  background-size: contain;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

.course-signup-panel__button:hover {
  background-color: #ffc05c;
}

.course-signup-panel__button--full {
  pointer-events: none;
  background: #D13C53;
}

.course-signup-panel__button--full::before {
  display: none;
}

.social-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-menu a {
  display: block;
  float: right;
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 25px;
  text-decoration: none;
  transition: color 300ms;
}

.social-menu a:hover {
  transition: color 200ms;
  color: rgba(0, 0, 0, 0.37);
}

.social-menu--dark a {
  color: rgba(255, 255, 255, 0.25);
}

.social-menu--dark a:hover {
  color: rgba(255, 255, 255, 0.4);
}

.cta-button {
  display: inline-block;
  padding: 0 60px;
  height: 50px;
  text-align: center;
  color: #fff !important;
  text-decoration: none !important;
  font: bold 17px/50px "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.2px;
  background-color: #F7B152;
  transition: background-color 200ms;
}

.cta-button:hover {
  background-color: #ffc05c;
  color: #fff;
  text-decoration: none;
}

.cta-button::before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(assets/right-arrow.svg) no-repeat;
  background-size: contain;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

.button {
  display: inline-block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #eee;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-weight: bold;
  font-size: 21px;
}

.button--primary {
  background: #F7B152;
  color: #fff;
}

.button--primary:hover, .button--primary:focus {
  color: #fff;
}

.button--primary:hover {
  background: #ffc05c;
}

.button--stretch {
  width: 100%;
}

.course-tile {
  position: relative;
  float: left;
  margin: 0 30px 30px 0;
  padding: 158px 0 0 18px;
  width: 310px;
  height: 200px;
  font-size: 21px;
  font-weight: bold;
  overflow: hidden;
  background-color: #000;
  color: #fff !important;
  text-decoration: none !important;
}

.course-tile:hover, .course-tile:focus {
  color: #fff;
}

.course-tile:nth-of-type(3n) {
  margin-right: 0;
}

.course-tile__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 600ms ease;
  background-size: cover;
  background-color: #000;
}

.course-tile__bg:hover {
  transform: scale(1.04);
  opacity: 0.7;
}

.course-tile__darken {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 53px;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.course-tile__text {
  position: absolute;
  bottom: 11px;
  z-index: 1;
}

.events .post {
  margin-top: 46px;
}

.events a {
  text-decoration: none !important;
}

.events h3 a {
  color: inherit;
}

.more-events-wrapper {
  padding: 60px 0;
  text-align: center;
}

.events-all {
  padding: 17px 0 60px;
}

.events-caption {
  margin: 60px 0 0;
  font: normal 30px "PT Sans", Helvetica, Arial, sans-serif;
}

.event-date {
  display: block;
  height: 135px;
  padding-top: 15px;
  border-radius: 6px;
  background: #D13D53;
  font: bold 19px Arial, sans-serif;
  text-align: center;
  color: #fff !important;
}

.event-date:hover {
  color: #fff;
}

.event-date__month {
  text-transform: uppercase;
}

.event-date__day {
  margin-top: 2px;
  font-size: 78px;
}

.event-details {
  margin-top: 17px;
}

.event-details__interactions a {
  padding: 2px 8px;
  border-radius: 3px;
  font-weight: bold;
  color: #C8C8C8 !important;
}

.event-details__interactions a:hover {
  color: #A6A6A6;
  background: rgba(0, 0, 0, 0.07);
}

html, body {
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
}

#header {
  position: relative;
  height: 400px;
  background-image: url("assets/header-bg.png");
  opacity: 0.8;
  color: #fff;
}

.header-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(-4deg, rgba(209, 61, 83, 0.83) 0%, #F7C652 100%);
  opacity: 0.85;
}

.nav-main {
  position: relative;
  float: right;
  margin-top: 40px;
}

.nav-main ul {
  list-style: none;
  padding-left: 0;
}

.nav-main li {
  float: left;
  margin-left: 30px;
}

.nav-main a {
  color: inherit;
  text-decoration: none;
}

.nav-main .magic-line {
  position: absolute;
  top: 40px;
  height: 2px;
  background: #fff;
  transition: transform 250ms ease;
}

.nav-main .magic-line.scaled {
  transform: scaleX(0);
}

.location-selection {
  position: relative;
  height: 45px;
}

.location-selection > ul {
  display: none;
  position: absolute;
  left: 50%;
  top: 35px;
  transform: translateX(-50%);
  padding: 8px 0;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.location-selection > ul::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 13px);
  width: 39px;
  height: 24px;
  background: url("assets/nubsi.svg") no-repeat top left/cover;
}

.location-selection > ul li {
  position: relative;
  margin: 0;
  width: 100%;
  z-index: 10;
}

.location-selection > ul a {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 24px;
  font: bold 16px/40px "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.75px;
  white-space: nowrap;
  color: #191919;
}

.location-selection > ul a:hover {
  background: #eee;
}

.location-selection:hover > ul {
  display: block;
}

.logolink {
  display: block;
  margin-top: 24px;
  padding-left: 63px;
  padding-top: 3px;
  height: 72px;
  background: url("assets/logo.png") top left/50px 72px no-repeat;
  font-size: 22px;
  font: 22px/25px "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.75px;
  color: #fff;
}

.logolink:hover {
  text-decoration: none;
  color: #fff;
}

.contentheader {
  position: relative;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
}

.contentheader__offsettop {
  position: absolute;
  bottom: 100%;
  width: 100%;
}

.contentheader__h1, .contentheader__currentschool {
  margin: 0;
}

.contentheader__h1 {
  margin-bottom: 3px;
}

.contentheader__currentschool {
  margin-bottom: 17px;
}

.article-content {
  padding: 30px 0;
}

.dark-col {
  background: linear-gradient(-180deg, #4A5059 0%, #29313E 100%);
}

.dark-col article.post p {
  color: #fff;
}

#footer {
  padding: 60px 0;
  background: linear-gradient(-180deg, #4A5059 0%, #29313E 100%);
  color: #fff;
}

#footer h2 {
  margin: 0 0 30px;
  font: bold 22px "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

#footer div > a {
  display: block;
  margin-bottom: 17px;
  font: 17px "PT Sans", Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.8);
}

#footer div > a:hover {
  color: #fff;
  text-decoration: none;
}
